body{
    /* background-image:  linear-gradient(279deg, rgba(255,114,53,1) 0%, rgba(250,255,53,1) 52%, rgba(255,114,53,1) 100%); */
    /* background-repeat: no-repeat; */
    background-attachment: fixed;
    
}

.main{
    background-image:  linear-gradient(279deg, rgba(255,114,53,1) 0%, rgba(250,255,53,1) 52%, rgba(255,114,53,1) 100%);
    background-attachment: fixed;
}


